<template>
	<v-footer id="core-footer" height="82">
		<div class="footer-items">
		datapopmusic@gmail.com
		</div>
	</v-footer>
</template>

<script>
export default {
	data: () => ({
		links: [
],
	}),
};
</script>

<style>
#core-footer {
	z-index: 0;
	background-color:  #138dd3;
}

.v-application {
  background-color: #138dd3;
}
</style>